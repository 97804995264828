import React from "react";
import { Helmet } from "react-helmet"

import PageWrapper from "../components/PageWrapper";
import Hero from '../sections/training-page/Hero';
import Content1 from '../sections/training-page/Content1';
import Radios from '../sections/training-page/Radios';
import CTA from '../sections/training-page/CTA';

// @ts-expect-error false positive image import failure
import annotatedImage from "../assets/image/training/pd365-annotations.png";
// @ts-expect-error false positive image import failure
import imgPD365 from "../assets/image/training/pd365-card.png";
// @ts-expect-error false positive image import failure
import imgPD565 from "../assets/image/training/pd565-card.png";
// @ts-expect-error false positive image import failure
import imgPNC370 from "../assets/image/training/pnc370-card.png";

const CaseStudyDetails = () => {
  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>Hytera PD365 Training</title>
          <meta name="description" content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK." />
        </Helmet>
        <Hero
          image={annotatedImage}
          model="Hytera PD365"
          pdf="https://shopsafe.uk/downloads/pd365-User-Guide.pdf"
        />
        <Content1
          video1="TUK_PywTtkk"
          video2="tREc7Qn-hvw"
          video3="CKNE7P7iXBQ"
        />
        <Radios
          radios={[{
            to: '/pd565-training',
            image: imgPD565
          }, {
            to: '/pd465-training',
            image: imgPD365
          }, {
            to: '/pnc370-training',
            image: imgPNC370
          }]}
        />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default CaseStudyDetails;
